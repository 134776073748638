<template>
  <Layout>
    <section class="my-3">
      <div class="row">
        <div class="col-lg-2 col-12"></div>
        <div class="col-lg-8 col-12">
          <form @submit.prevent="onSubmit">
            <div class="card">
              <div class="card-body">
                <h4 class="mb-4">Dados do cartão</h4>

                <div class="row">
                  <div class="col-lg-6 col md-6 col-12">
                    <b-form-group
                      label="Número do cartão"
                      label-for="creditcardnumber"
                    >
                      <b-form-input
                        id="creditcardnumber"
                        type="text"
                        v-model="$v.creditCard.number.$model"
                        :state="validateState('creditCard.number')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6 col md-6 col-12">
                    <b-form-group
                      label="Nome impresso no cartão"
                      label-for="sobrenome"
                    >
                      <b-form-input
                        id="sobrenome"
                        type="text"
                        v-model="$v.creditCard.name.$model"
                        :state="validateState('creditCard.name')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6 col md-6 col-12">
                    <b-form-group
                      label="Data de nascimento do titular do cartão"
                      label-for="datanascimento"
                    >
                      <b-form-input
                        id="datanascimento"
                        v-mask="'##/##/####'"
                        type="string"
                        v-model="$v.form.client.birthDate.$model"
                        :state="validateState('form.client.birthDate')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-2 col md-2 col-4">
                    <b-form-group
                      label="Mês validade"
                      label-for="creditcardmonth"
                    >
                      <b-form-input
                        id="creditcardmonth"
                        type="text"
                        v-mask="'##'"
                        v-model="$v.creditCard.month.$model"
                        :state="validateState('creditCard.month')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-2 col md-2 col-4">
                    <b-form-group
                      label="Ano validade"
                      label-for="creditcardyear"
                    >
                      <b-form-input
                        id="creditcardyear"
                        type="text"
                        v-mask="'####'"
                        v-model="$v.creditCard.year.$model"
                        :state="validateState('creditCard.year')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-2 col md-2 col-4">
                    <b-form-group label="CVV" label-for="creditcardcvv">
                      <b-form-input
                        id="creditcardcvv"
                        v-mask="'###'"
                        type="text"
                        v-model="$v.creditCard.cvv.$model"
                        :state="validateState('creditCard.cvv')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h4 class="mb-4">Dados do comprador</h4>

                <div class="row">
                  <div class="col-lg-6 col md-6 col-12">
                    <b-form-group label="Nome do comprador" label-for="name">
                      <b-form-input
                        id="name"
                        type="text"
                        v-model="$v.form.client.name.$model"
                        :state="validateState('form.client.name')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6 col md-6 col-12">
                    <b-form-group label="E-mail do comprador" label-for="email">
                      <b-form-input
                        id="email"
                        type="text"
                        v-model="$v.form.client.email.$model"
                        :state="validateState('form.client.email')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6 col md-6 col-12">
                    <b-form-group label="CPF do comprador" label-for="cpf">
                      <b-form-input
                        id="cpf"
                        v-mask="'###.###.###-##'"
                        type="text"
                        v-model="$v.form.client.cpf.$model"
                        :state="validateState('form.client.cpf')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-2 col md-2 col-4">
                    <b-form-group label="DDD" label-for="ddd">
                      <b-form-input
                        id="ddd"
                        v-mask="'##'"
                        type="text"
                        v-model="$v.form.client.phoneAreaCode.$model"
                        :state="validateState('form.client.phoneAreaCode')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col md-2 col-4">
                    <b-form-group label="Telefone" label-for="number">
                      <b-form-input
                        id="number"
                        v-mask="'#####-####'"
                        type="text"
                        v-model="$v.form.client.phone.$model"
                        :state="validateState('form.client.phone')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h4 class="mb-4">Endereço do comprador</h4>

                <div class="row">
                  <div class="col-lg-8 col md-8 col-12">
                    <b-form-group label="Endereço" label-for="street">
                      <b-form-input
                        id="street"
                        type="text"
                        v-model="$v.form.address.street.$model"
                        :state="validateState('form.address.street')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col md-4 col-12">
                    <b-form-group label="Número" label-for="addressnumber">
                      <b-form-input
                        id="addressnumber"
                        type="text"
                        v-model="$v.form.address.number.$model"
                        :state="validateState('form.address.number')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col md-4 col-12">
                    <b-form-group label="Complemento" label-for="complement">
                      <b-form-input
                        id="complement"
                        type="text"
                        v-model="$v.form.address.complement.$model"
                        :state="validateState('form.address.complement')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col md-4 col-12">
                    <b-form-group label="Bairro" label-for="district">
                      <b-form-input
                        id="district"
                        type="text"
                        v-model="$v.form.address.district.$model"
                        :state="validateState('form.address.district')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col md-4 col-12">
                    <b-form-group label="Cidade" label-for="city">
                      <b-form-input
                        id="city"
                        type="text"
                        v-model="$v.form.address.city.$model"
                        :state="validateState('form.address.city')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-2 col md-4 col-12 text-upper">
                    <b-form-group label="UF" label-for="uf">
                      <b-form-select
                        id="uf"
                        type="text"
                        maxLength="2"
                        v-model="$v.form.address.state.$model"
                        :state="validateState('form.address.state')"
                        :options="[
                          { text: 'Acre', value: 'AC' },
                          { text: 'Alagoas', value: 'AL' },
                          { text: 'Amapá', value: 'AP' },
                          { text: 'Amazonas', value: 'AM' },
                          { text: 'Bahia', value: 'BA' },
                          { text: 'Ceará', value: 'CE' },
                          { text: 'Distrito Federal', value: 'DF' },
                          { text: 'Espírito Santo', value: 'ES' },
                          { text: 'Goiás', value: 'GO' },
                          { text: 'Maranhão', value: 'MA' },
                          { text: 'Mato Grosso', value: 'MT' },
                          { text: 'Mato Grosso do Sul', value: 'MS' },
                          { text: 'Minas Gerais', value: 'MG' },
                          { text: 'Pará', value: 'PA' },
                          { text: 'Paraíba', value: 'PB' },
                          { text: 'Paraná', value: 'PR' },
                          { text: 'Pernambuco', value: 'PE' },
                          { text: 'Piauí', value: 'PI' },
                          { text: 'Rio de Janeiro', value: 'RJ' },
                          { text: 'Rio Grande do Norte', value: 'RN' },
                          { text: 'Rio Grande do Sul', value: 'RS' },
                          { text: 'Rondônia', value: 'RO' },
                          { text: 'Roraima', value: 'RR' },
                          { text: 'Santa Catarina', value: 'SC' },
                          { text: 'São Paulo', value: 'SP' },
                          { text: 'Sergipe', value: 'SE' },
                          { text: 'Tocantins', value: 'TO' },
                        ]"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col md-4 col-12">
                    <b-form-group label="CEP" label-for="postalcode">
                      <b-form-input
                        id="postalcode"
                        type="text"
                        v-mask="'#####-###'"
                        v-model="$v.form.address.postalCode.$model"
                        :state="validateState('form.address.postalCode')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between my-2">
                  <button
                    type="reset"
                    @click.prevent="resetForm"
                    class="btn btn-secondary"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="loading"
                  >
                    <i class="mdi mdi-check-bold"></i>
                    Confimar assinatura
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </Layout>
</template>

<script>
import { mapState } from "vuex";
import { authMethods } from "@/state/helpers";
import * as api from "@/api";
import Layout from "../layouts/main";

import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  integer,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";

const formDefaults = () => ({
  plan: "",
  creditCardToken: "",
  senderHash: "",

  client: {
    name: "",
    cpf: "",
    phoneAreaCode: "",
    phone: "",
    email: "",
    birthDate: "",
  },

  address: {
    street: "",
    number: "",
    complement: "",
    district: "",
    postalCode: "",
    city: "",
    state: "",
    country: "BRA",
  },
});

export default {
  name: "editProfile",
  components: {
    Layout,
  },
  data() {
    return {
      form: formDefaults(),
      creditCard: {
        name: "",
        number: "",
        month: "",
        year: "",
        cvv: "",
      },
      loading: false,
    };
  },

  validations: {
    creditCard: {
      name: { required, minLength: minLength(5) },
      number: {
        required,
        numeric,
        minLength: minLength(16),
        maxLength: maxLength(16),
      },
      month: {
        required,
        integer,
        minValue: minValue(1),
        maxValue: maxValue(12),
      },
      year: {
        required,
        integer,
        minValue: minValue(new Date().getFullYear()),
        maxValue: maxValue(3000),
      },
      cvv: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(3),
        integer,
      },
    },

    form: {
      client: {
        name: { required, minLength: minLength(5) },
        cpf: { required, minLength: minLength(14), maxLength: maxLength(14) },
        phoneAreaCode: { required, minLength: minLength(2) },
        phone: { required, minLength: minLength(9) },
        email: { required, email },
        birthDate: { required, minLength: minLength(10) },
      },

      address: {
        street: { required, minLength: minLength(5), maxLength: maxLength(80) },
        number: { required },
        complement: {
          minLength: minLength(0),
          maxLength: maxLength(40),
        },
        district: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(60),
        },
        postalCode: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(9),
        },
        city: { required, minLength: minLength(2), maxLength: maxLength(60) },
        state: { required, minLength: minLength(2), maxLength: maxLength(2) },
        country: { required },
      },
    },
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    canSubmit() {
      if (this.loading) {
        return false;
      }

      if (
        this.$v.form.$anyError ||
        this.$v.form.$invalid ||
        this.$v.creditCard.$anyError ||
        this.$v.creditCard.$invalid
      ) {
        return false;
      }

      return true;
    },
  },

  created() {
    this.resetForm();

    if (this.currentUser.billingData) {
      for (const parentKey of ["address", "client"]) {
        if (!this.currentUser.billingData[parentKey]) {
          continue;
        }

        for (const key of Object.keys(this.form[parentKey])) {
          const oldData = this.currentUser.billingData[parentKey][key];
          if (oldData) {
            this.form[parentKey][key] = oldData;
          }
        }
      }
    }

    this.form.plan = this.$route.params.code;
  },

  mounted() {
    this.getSession();
  },

  methods: {
    ...authMethods,

    validateState(name) {
      const { $dirty, $error } = name
        .split(".")
        .reduce((acc, k) => acc[k], this.$v);
      return $dirty ? !$error : null;
    },

    resetForm() {
      this.form = formDefaults();
    },
    getSenderHash() {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        PagSeguroDirectPayment.onSenderHashReady((response) => {
          if (response.status === "error") {
            // eslint-disable-next-line no-console
            console.log(response.message);
            return false;
          }

          this.form.senderHash = response.senderHash;

          return (
            api
              .doPayment(this.form)
              .then(() => {
                this.$swal(
                  "Sucesso",
                  "Pagamento realizado com sucesso",
                  "success"
                );
                this.resetForm();

                return this.$router.push({ name: "dashboard" });
              })
              .then(resolve)
              // eslint-disable-next-line no-unused-vars
              .catch(reject)
          );
        });
      });
    },
    async getBrand() {
      const response = await new Promise((success, error) => {
        const cardBin = this.creditCard.number;

        // eslint-disable-next-line no-undef
        PagSeguroDirectPayment.getBrand({
          cardBin,
          success,
          error,
        });
      });

      return this.getCardToken(response.brand.name);
    },
    getCardToken(brand) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        PagSeguroDirectPayment.createCardToken({
          brand,
          cardNumber: this.creditCard.number,
          cvv: this.creditCard.cvv,
          expirationMonth: this.creditCard.month,
          expirationYear: this.creditCard.year,
          success: (response) => {
            this.form.creditCardToken = response.card.token;
            resolve();
          },
          // eslint-disable-next-line no-unused-vars
          error: reject,
        });
      });
    },

    getSession() {
      return api
        .getSession()
        .then((response) => {
          // eslint-disable-next-line no-undef
          PagSeguroDirectPayment.setSessionId(response.session.id);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },

    async onSubmit() {
      this.$v.form.$touch();

      if (!this.canSubmit) {
        return;
      }

      const dateMoment = this.$moment(
        this.form.client.birthDate,
        "DD/MM/YYYY",
        true
      );

      const validateDate =
        dateMoment.isValid() && dateMoment.isSameOrBefore(this.$moment());

      if (this.form.birthDate && !validateDate) {
        return this.$swal("Opa", "Você precisa uma data válida!", "warning");
      }

      this.loading = true;

      const handleError = (err) => {
        let errors = [];

        try {
          errors = [].concat(err.response.data.errors.error);
        } catch (_) {
          // ...
        }

        // eslint-disable-next-line no-console
        console.error(err, errors);

        const invalidErrors = errors.some(
          (e) =>
            e.message.includes("invalid value") ||
            e.message.includes("required")
        );

        if (invalidErrors) {
          return this.$swal("Opa", "Dados faltando ou inválidos!", "error");
        }
        this.$swal("Opa", "Algo deu errado, verifique seus dados", "error");
      };

      let canRetry = false;
      try {
        await this._doSubmit();
      } catch (err) {
        // Generic error from PagSeguro
        canRetry = !!err.error;

        if (!canRetry) {
          handleError(err);
        }
      }

      if (canRetry) {
        try {
          await this.getSession();
          await this._doSubmit();
        } catch (err) {
          handleError(err);
        }
      }

      this.loading = false;
    },

    async _doSubmit() {
      await this.getBrand();
      await this.getSenderHash();
    },
  },
};
</script>

<style scoped>
.vue-dropzone {
  text-align: center;
}
</style>

<style>
.editing-user #user-vertical-cart-avatar {
  min-width: 256px;
  min-height: 256px;
  height: auto;
  background-color: rgba(12, 12, 12, 0.1);
  max-width: 256px;
  margin: 0 auto;
}
.editing-user #user-vertical-cart-avatar:hover {
  cursor: pointer;
}
.editing-user .cropper-view-box {
  border-radius: 50% !important;
}

.text-upper {
  text-transform: uppercase;
}
</style>
